import {getSTSToken} from "@/api/tool/common";
import COS from 'cos-js-sdk-v5';
import OSS from 'ali-oss'


//这种会自动更新token ,可以使用这个
/*
  const baseUrl= process.env.VUE_APP_BASE_API
  const cos = new COS({
  getAuthorization: function (options, callback) {
    const url = baseUrl+'/common/oss'
    const xhr = new XMLHttpRequest()
    let data = null
    let credentials = null
    xhr.open('POST', url, true)
    xhr.onload = function (e) {
      try {
        data = JSON.parse(e.target.responseText);
        credentials = data.data;
      } catch (e) {
        console.log(e)
      }
      if (!data || !credentials) {
        return console.error('credentials invalid:\n' + JSON.stringify(data, null, 2));
      }
      callback({
        TmpSecretId: credentials.accessKeyId,        // 临时密钥的 tmpSecretId
        TmpSecretKey: credentials.accessKeySecret,      // 临时密钥的 tmpSecretKey
        SecurityToken: credentials.securityToken, // 临时密钥的 sessionToken
        // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
        StartTime: credentials.startTime, // 时间戳，单位秒，如：1580000000
        ExpiredTime: credentials.expiration,
      })
    }
    xhr.send();
  }
})*/



export function ossClient(stsToken) {
  //console.log('oss临时密钥', stsToken);
  //先请求这个获取临时token的方法,获取到临时token,再初始化oss
  return new OSS({
    //临时token
    stsToken: stsToken.securityToken,
    //临时key
    accessKeyId: stsToken.accessKeyId,
    //临时密钥
    accessKeySecret: stsToken.accessKeySecret,
    bucket: stsToken.bucket,
    endpoint: stsToken.endpoint,
    region: stsToken.region

  })
}


// oss 域名地址
export function getHeaders() {
  const headers = {
    // 指定该Object被下载时的内容编码格式。
    "Content-Encoding": "UTF-8",
    // 指定CopyObject操作时是否覆盖同名目标Object。此处设置为true，表示禁止覆盖同名Object。
    "x-oss-forbid-overwrite": "true"
  }
  return headers
}

/**
 * 上传方法
 * 支持腾讯云,阿里云的直传
 * @param filePath 文件名称,自己拼好前面的日期 , 可以调用uploadOss 方法生成文件的前缀
 * @param file 具体的文件
 */
export async function uploadObject(filePath, file) {
  //调用后台接口获取临时token
  let stsToken = await getSTSToken()
  if (stsToken.data.type === 'aliyun') {
    return uploadOssObject(filePath,file,stsToken)
  } else if (stsToken.data.type === 'tencent') {
    return uploadCosObject(filePath, file, stsToken);
  }
}

export async function uploadOssObject(filePath, file,stsToken) {
  let headers = getHeaders()
  let result = await ossClient(stsToken.data).multipartUpload(filePath, file, {headers})
  if (result.res.status === 200) {
    return stsToken.data.staticUrl + filePath
  }
}

export async function uploadCosObject(filePath, file,token) {
  const cos = new COS({
    SecretId: token.data.accessKeyId,        // 临时密钥的 tmpSecretId
    SecretKey: token.data.accessKeySecret,      // 临时密钥的 tmpSecretKey
    XCosSecurityToken: token.data.securityToken, // 临时密钥的 sessionToken
  })

  let data = await cos.uploadFile({
    Bucket: token.data.bucket, /* 填入您自己的存储桶，必须字段 */
    Region: token.data.region,  /* 存储桶所在地域，例如 ap-beijing，必须字段 */
    Key: filePath,  /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
    Body: file,
    SliceSize: 1024 * 1024 * 5,     /* 触发分块上传的阈值，超过5MB使用分块上传，非必须 */
    onProgress: function(progressData) {
      console.log(JSON.stringify(progressData));
    },
    onFileFinish: function (err, data, options) {   /* 非必须 */
      console.log(options.Key + '上传' + (err ? '失败' : '完成'));
    },

  });
  if (data.statusCode === 200) {
    return token.data.staticUrl+filePath;
  }
  return '';
}


export function uploadOss(fileName, prefix) {
  // 获取文件后缀
  const suffix = fileName.substring(fileName.lastIndexOf('.'))
  // 生成文件名称
  const filePath = generateFilePath(prefix, suffix)
  return filePath
}

/**
 *  生成文件扩展名称
 * @param {prefix} 路径前缀
 * @param {suffix} 文件后缀
 */
export function generateFilePath(prefix, suffix) {
  let now = new Date()
  let year = now.getFullYear(),
    month = now.getMonth() + 1,
    day = now.getDate()
  let dir = prefix + '/' + year + '/' + (month < 10 ? '0' + month : month) + '/' + (day < 10 ? '0' + day : day) +
    '/' + now.getTime() + generateRdStr() + suffix
  return dir
}

/**
 * 生成随机字符串
 * @returns
 */
export function generateRdStr() {
  let len = 15
  let text = ''
  let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  for (let i = 0; i < len; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }
  return text
}

